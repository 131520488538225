// IPUMS Site css version 1.1
//  Updated to work with isrdi-common.1.6 & base-ipums-drupal-shim.1.1
//  pulled in things from base-ipums-drupal.1.0
// IPUMS Site css version 1.0
//  Created to work with isrdi-common.1.4 & base-ipums-drupal.1.0

@import 'common-isrdi-variables.1.0';
$width_gutter: 1.25rem;
@import 'common-isrdi-extends.1.1';

// ***
// * BEGIN Basic Layout
// ***
header, div[id="content"], footer {
  position:relative;
  width:100%;
  max-width:$width_wide_page;
  margin-left:auto;
  margin-right:auto;
}
body.site-name-ipums,
body.site-name-ipums-d9 {
  // extra specificity needed to override drupal global.css
  #site-header { border-bottom-width: 0;}
}
div[id="siteLogo"] img {
  width:260px;
  height: auto;
}
div[id="main"] {
  position:relative;
}
.pane-page-content {
  margin-left:$width_gutter;
}
.pane-page-breadcrumb {
  ol {
    margin:1rem 0 1.5rem 0;
    padding-left:0;
  }
  li {
    position:relative;
    display:inline;
    margin:0;
    list-style:none;
  }
}
.pane-pane-messages {
  .messages--warning, .messages--status {
    margin-bottom:$width_gutter;
  }
  ul.tabs-primary {  
    @extend %menu-list;
    margin-bottom:0;
  }
}
footer {
  ul.menu {
    margin-bottom: $width_gutter;
  }
}
div[id="main-menu"] {
  position:absolute;
  top:4rem;
  right:0.5rem;
  /* box sizing border box appears to break superfish
     making the overflow visible seems to fix it for now
  */
  .sf-depth-1 > ul {
    overflow:visible !important;
  }
  div.sf-accordion-toggle {
    text-align:right;
  }
}

.pane-pane-messages {
  ul.tabs-primary {  
    margin-top:-1*$width_gutter;
    margin-left:-1*$width_gutter;
    padding-left:$width_gutter;
  }
}


// ***
// * END Basic Layout
// ***

// ***
// * BEGIN Visual Styles for Page Structural Elements
// ***
html, body {
  background-color: $color_primary_dark;
}
header {
  min-height: auto;
  background: $color_primary_dark url("../_img/bkg-Number-Bar-IPUMS.png") bottom left/auto 1.1rem repeat-x;  
}

div[id="main"] {
  padding: $width_gutter $width_gutter 3*$width_gutter $width_gutter;  
}
div[id="main-menu"] {
  font-size:0.933333em;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  padding-top:0.8rem;
  li {
    background-color: $color_primary_dark;
    a {
      color:$color_near_white;
      text-decoration:none;
      border: 1px outset $color_near_white;
      &:hover, &:focus, &:active {
        color: $color_near_white;
        background-color: $color_bkg_dark;
      }        
    }
    &.sfHover {
      background:$color_bkg_dark;
      & > a {
        color: $color_near_white;
      }
    }
    li {
      font-size: $base_font_size;
      line-height: $base_font_size;

      background-color:$color_bkg_med_light;
      a {
        color: $color_primary_dark;
        padding:0.5em 1em;
        &:hover {
          color: $color_near_white;
          background-color: $color_primary_med_light;
        }
        &:focus, &:active {
          color: $color_near_white;
          background-color: $color_bkg_dark;
        }
        .sf-sub-indicator {
          background-image:url("../_img/superfish/arrows-777777.png");
          top:0.55em;
        }
      }
    }
  }
  li.sf-depth-1 {
    & > a {
      padding:0.4em 1em;
      border-top:none;
      border-bottom:none;
      &.sf-with-ul {
        padding-right:2em;
        & > .sf-sub-indicator {
          top:0.4em;
        }
      }
      &[href^="http"] {
        padding-right:2em;
        background-size: auto 50%;
        background-position: right 0.6em top 0.4em;
      }
    }
  }
  .sf-depth-2 {
    &.menuparent {
      position:relative;
    }
    a[href^="http"] {
      background-size: auto 50%;
      background-position: right 1em center;
    }
  }
  /* when menu is horizontal, hide first and last lines */
  .sf-horizontal > li.sf-depth-1 {
    &:first-child > a {
      padding-left:0;
      border-left:none;
    }
    &:last-child > a {
      border-right:none;
    }
    & > ul {
      margin-top:-0.8em;
    }
  }
  .sf-accordion-toggle {
    a {
      display:inline-block;
      padding:0.5rem;
      color:$color_primary_dark;
      background-color: $color_bkg_med_light;
      text-align: center;
      text-decoration: none;
      &:hover, &:focus {
        color:$color_near_white;
        background-color:$color_primary_med_light;
        text-decoration: underline;
      }
    }
  }
  .sf-accordion {
    border-top:1px solid $color_primary_med_light;
    li.sf-depth-1 > a {
      padding:0.5em 1em;
    }
    li.sf-depth-3 > a {
      padding-left:2em;
    }
  }
}
div[id="aside"] {
  padding: $width_gutter;  
}
div[id="banner"] {
  background-color: $color_primary_light;
  padding: $width_gutter $width_gutter;
  border-bottom: 2px solid $color_bkg_dark;
}
footer {
  font-size: 1.5rem;
  padding:2rem 0;
  text-align: center;
  color:transparentize($color_near_white,.20);
  a, a[href] {
    &:link, &:visited {
      color:transparentize($color_primary_med,.30);
      text-decoration: none;
    }
    &:hover, &:active {
      color:$color_near_white;
      text-decoration: underline;
    }
  }
  .menu {
    @extend %menu-list;
    a {
      text-decoration: none;
      color:transparentize($color_near_white,.20);
      &:hover {
        text-decoration: underline;
        color:$color_near_white;
      }
    }
  }
}
// ***
// * END Visual Styles for Page Structural Elements
// ***

// ***
// * BEGIN Specialized Classes
// ***
/* override link icons when necessary */
p[id="skip-link"] {
  @extend %sr-only;
}
a.button {
  @include button($color_primary, transparent, $color_near_white, $color_primary_med_dark, $color_primary_med, $color_secondary_dark, 2.5rem );
}

body.front .view-ipums-project-list a,
.social-widget a,
.node-type-project p:last-child .button,
.aside-widget a
{
  @extend %no-link-icon;
}
body.front .view-ipums-project-list a.button,
.node-type-project p:last-child .button,
.aside-widget a.button
{
  padding-right:2.5rem;
}

body.front {
  // home page project list
  .view-ipums-project-list .view-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $width_gutter;  
    // flex-flow: row wrap;
    .views-row {
      position:relative;
      padding: 2*$width_gutter 1.5*$width-gutter
              4.5*$width_gutter 1.5*$width-gutter;
      border: 1px solid $color_primary_light;
      .project-logo {
        position:relative;
        // the % based top padding when combined with 
        //   the absolutely positioned image as the only
        //   content will cause that image to be displayed
        //   with a height based on a percentage of the 
        //   parent grid container's width.  make sure it 
        //   works with the widest logo and you should be good
        padding-top: 21%;
        margin:0 -0.5em 3rem -0.5em;
        img.panopoly-image-original {
          // the position, height 100%, and width auto
          //   when combined with the % based padding-top
          //   above will cause the image to be auto sized
          //   to a height based on a percentage width of 
          //   the container grid items
          position:absolute;
          height:100%;
          width:auto;
          top:0;
          // the left, right, & margin will center the logo
          left:0;
          right:0;
          margin:0 auto;
        }
      }
      p {
        text-align: center;
        font-size:0.95em;
        line-height:1.4em;
      }
      a.button {
        position:absolute;
        bottom:2rem;
        /* width has to be set to center an 
           absolutely positioned element    */
        width:12.5rem;
        text-align: center;
        /* center the left edge of the element */
        left:50%;
        /* move it left half the width of the element */
        margin-left: -6.25rem;
        border-color: $color_primary_med_light;
      }
    }
  }
  // home page blog pane
  .pane-ipums-blog-feed-blog-pane {
    @extend %clearfix;
    background-color: $color_bkg_med_light;
    padding:$width_gutter;
    margin-top:$width_gutter;
    h2 {
      margin-top:0;
      padding-top:0.5em;
      font-size:1.2em;
      a {
        text-decoration: none;
        &:hover {
          text-decoration:underline;
        }
      }
    }
    img {
      padding-right:$width_gutter;
    }
  }
}
// all asides
.aside-widget {
  background-color:$color_bkg_med_light;
  text-align: center;
  padding: $width_gutter
            $width_gutter
            $width_gutter*2  // give more space at the bottom
            $width_gutter;
  margin-bottom:$width_gutter;

  /* using line behind title technique from
      http://codepen.io/ericrasch/pen/Irlpm
  */
  h2 {
    @include header-bars($color_secondary_dark);
    margin-top:$width_gutter;
    color: $color_secondary_dark;
  }
  .button {
    margin-top:$width_gutter;
  }
}
.alert-widget, .highlight-widget {
  border:1px solid $color_primary_med_dark;
}
.alert-widget {
  background-color:$color_primary_light;
  a.button {
    background-color:$color_primary_dark;
    border:1px solid $color_primary_dark;
    color:$color_near_white;
    &:hover {
      color:$color_primary_dark;
      background-color:$color_near_white;
    }
  }
  h2 {
    color: $color_primary_dark;    
  }
}
// .events-widget {
//   h3 {
//     text-decoration: none;
//   }
//   .event-location {
//     display: block;
//   }
// }
.social-widget {
  padding-bottom:1rem;
  padding-top:3rem;
  text-transform: uppercase;
  color: $color_primary_med;
  text-align:right;
  img {
    width:3.2rem;
    height:3.2rem;
    margin:0 0.3rem;
  }
}
.node-project {
  /* button on bottom of project page */
  a.button:last-child {
    margin-top:1.5*$width_gutter;
  }
  /* header/subheader */
  h1 {
    margin-bottom:0.4rem;
  }
  .pane-node-field-subtitle {
    margin-bottom:2rem;
    font-size:1.15em;
  }
}
// current project display on sidebar
.view-ipums-current-project {
  .site-widget {
    background-color: $color_primary_dark;
    a.button {
      color: $color_primary_light;
      font-size: 0.8em;
      line-height:1.5em;
      border-color:$color_near_white;
      &:first-line {
        font-size:1.4em;
      }
    }
  }  
}
// events page
body.page-events #main {
  .views-row {
    h3 {
      margin-top:3*$width_gutter;
      margin-bottom:$width_gutter;
    }
    & > span, & > p {
      margin-left:$width_gutter;
    }
    .event-location {
      display:block;
    }
  }
}
.node-type-project, .node-type-project-revision {
  .active-link {
    background-color:$color_link;
    a:link, a:visited {
      text-decoration:none;
      color:$color_near_white;
      cursor:default;
    }
  }
}
// ***
// * END Specialized Classes
// ***


// ***
// * BEGIN General Tag Styling
// ***
a.button {
  text-transform:uppercase;
}
// ***
// * END General Tag Styling
// ***


// ***
// * BEGIN flexbox based layout adaptations
// ***
// when small mode has enough room for 2 learn more/visit site
// buttons on the home page
@media all and (min-width:465px) {
  body.front .view-ipums-project-list .view-content {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (min-width: $width_one_col) {
  body.front .view-ipums-project-list .view-content {
    grid-template-columns: 1fr;
  }
}
@media all and (min-width: $width_one_col) {
  div[id="content"] {
    // -ms-box-orient: horizontal;
    display: flex;
  
    // -webkit-justify-content: space-around;
    // justify-content: space-around;
    flex-flow: row wrap;
    // -webkit-align-items: stretch;
    // align-items: stretch;
  }
  div[id="banner"] {
    flex: 1 100%;
  }
  div[id="main"] {
    flex: 2;
  }
  div[id="aside"] {
    flex: 1;
    padding-left:0;
  }
}
// when medium mode has enough room for 2 learn more/visit site
// buttons on the home page
@media all and (min-width: 700px) {
  body.front .view-ipums-project-list .view-content {
    grid-template-columns: 1fr 1fr;
  }
}
@media all and (min-width: $width_narrow_page) {
  div[id="main-menu"] {
    left:auto;
    width:64.5%;
    border-top: 1px solid $color_primary_med_light;
  }
  div[id="main"] {
    flex: 3;
  }
  div[id="aside"] {
    // flex: 0 0 25%;
  }
}
// when wide mode has enough room for 3 learn more/visit site
// buttons on the home page
@media all and (min-width: 920px) {
  body.front .view-ipums-project-list .view-content {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
// ***
// * END flexbox based layout adaptations
// ***

// pull in from ten7 ipums themes > shared > themes > project > css > sites > ipums > ipums.css

body.site-name-ipums,
body.site-name-ipums-d9 {
  --button-bg: #00263a;
  --button-bg-hover: #bed2dd;
  --button-text-color: #fff;
  --button-text-color-hover: #00263a;
  --primary: #bed2dd;
  --primary-darker: #00263a;
  --secondary: #11374b;
  --text-light: #fbfcfc;
  --text-dark: #00263a;
  --link: #4e6c7d;
  --link-hover: #7e919b;
  --link-visited: #4e6c7d;
  --table-bg: #e1e9ee;
  --background-light: #f1f5f7;
  --max-page-width: 1200px; }

.jim-look-here {
  color: rgba(51, 51, 51, 0.15); }

.header-navigation {
  display: none; }

@media (min-width: 50em) {
  body.site-name-ipums,
  body.site-name-ipums-d9 {
    // extra specificity needed to override drupal global.css
    .header-navigation {
      width: 64.5%;
      display: block;
    }
    .site-logo {
      width: 35.5%;
    }
  }
}

body.site-name-ipums,
body.site-name-ipums-d9 {
  // extra specificity needed to override drupal global.css
  h1.page-title, h2.page-title {
    margin-top: 1.5rem;
  }
}

.responsive-menu-toggle-wrapper.responsive-menu-toggle {
  display: block; }
  @media (min-width: 50em) {
    .responsive-menu-toggle-wrapper.responsive-menu-toggle {
      display: none; } }

.page {
  background-color: var(--secondary); }

#site-header {
  max-width: 100%;
  border-bottom: 0; }

#main-menu {
  position: static; }

#content {
  padding-top: 1.25rem;
  padding-bottom: 3.75rem; }

body:not(.path-frontpage) #content {
  padding-left: 2rem;
  padding-right: 2rem; }

.banner {
  background-color: var(--primary);
  padding: 1.25rem 1.25rem;
  border-bottom: 2px solid #11374b; }

.site-name-ipums .button:hover {
  color: #fff; }

.site-name-ipums .aside-widget.alert-widget .button:hover {
  color: var(--primary-darker); }

.site-name-ipums .aside-widget.site-widget .button:hover {
  color: var(--primary); }

.site-name-ipums .aside-widget .button:hover {
  color: #fff; }

.breadcrumb ol {
  margin: 1rem 0 1.5rem 0;
  padding-left: 0; }
  .breadcrumb ol li,
  .breadcrumb ol li:last-child {
    font-size: 15px; }
  .breadcrumb ol a {
    font-size: 15px;
    text-decoration: underline;
    border-bottom: 0; }
    .breadcrumb ol a:hover {
      background-color: transparent;
      color: var(--link-hover); }

.pane-ipums-blog-feed-blog-pane img {
  float: left; }

.node-type-project .block-page-title-block h1 {
  margin-bottom: 0; }

.homepage-project-list .field__items,
#block-homepageprojectlist .field__items {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.25rem; }
  .homepage-project-list .field__items > .field__item,
  #block-homepageprojectlist .field__items > .field__item {
    position: relative;
    padding: 2.5rem 1.875rem 5.625rem 1.875rem;
    border: 1px solid var(--secondary);
    display: flex; }
    .homepage-project-list .field__items > .field__item article,
    #block-homepageprojectlist .field__items > .field__item article {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: static; }
  .homepage-project-list .field__items p,
  #block-homepageprojectlist .field__items p {
    text-align: center;
    font-size: 0.95em;
    line-height: 1.4em; }
  .homepage-project-list .field__items a,
  #block-homepageprojectlist .field__items a {
    white-space: nowrap; }
  .homepage-project-list .field__items a.button,
  #block-homepageprojectlist .field__items a.button {
    width: 12.5rem;
    text-align: center;
    border-color: #7a99ac;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    margin-left: -6.25rem; }
    .homepage-project-list .field__items a.button:hover,
    #block-homepageprojectlist .field__items a.button:hover {
      color: #fff; }
  .homepage-project-list .field__items .project-logo img,
  #block-homepageprojectlist .field__items .project-logo img {
    max-height: 50px;
    width: auto; }

.homepage-project-list .field__items,
#block-homepageprojectlist .field__items,
body.path-frontpage .view-ipums-project-list .view-content {
  grid-template-columns: minmax(0, 1fr); }
  @media screen and (min-width: 465px) {
    .homepage-project-list .field__items,
    #block-homepageprojectlist .field__items,
    body.path-frontpage .view-ipums-project-list .view-content {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); } }
  @media screen and (min-width: 550px) {
    .homepage-project-list .field__items,
    #block-homepageprojectlist .field__items,
    body.path-frontpage .view-ipums-project-list .view-content {
      grid-template-columns: minmax(0, 1fr); } }
  @media screen and (min-width: 700px) {
    .homepage-project-list .field__items,
    #block-homepageprojectlist .field__items,
    body.path-frontpage .view-ipums-project-list .view-content {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr); } }
  @media screen and (min-width: 920px) {
    .homepage-project-list .field__items,
    #block-homepageprojectlist .field__items,
    body.path-frontpage .view-ipums-project-list .view-content {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr); } }

table.project-topics-table .project-usa {
  text-align: center;
  font-size: 150%;
  color: #00a0dc; }

table.project-topics-table .project-nhgis {
  text-align: center;
  font-size: 150%;
  color: #008f4c; }

table.project-topics-table .project-international {
  text-align: center;
  font-size: 150%;
  color: #3975b7; }

table.project-topics-table .project-ihgis {
  text-align: center;
  font-size: 150%;
  color: #98c11d; }

table.project-topics-table .project-cps {
  text-align: center;
  font-size: 150%;
  color: #d7572b; }

table.project-topics-table .project-time {
  text-align: center;
  font-size: 150%;
  color: #dbb216; }

table.project-topics-table .project-nhis {
  text-align: center;
  font-size: 150%;
  color: #279989; }

table.project-topics-table .project-meps {
  text-align: center;
  font-size: 150%;
  color: #279989; }

table.project-topics-table .project-dhs {
  text-align: center;
  font-size: 150%;
  color: #98579b; }

table.project-topics-table .project-pma {
  text-align: center;
  font-size: 150%;
  color: #98579b; }

table.project-topics-table .project-mics {
  text-align: center;
  font-size: 150%;
  color: #98579b; }
  
table.project-topics-table .project-highered {
  text-align: center;
  font-size: 150%;
  color: #bf0d3e; }

table.project-topics-table .project-terra {
  text-align: center;
  font-size: 150%;
  color: #98c11d; }

#main-menu {
  border-top: 1px solid #7a99ac; }

#main-menu li li {
  font-size: 1.5rem;
  line-height: 1.5rem;
  background-color: #f1f5f7; }

#site-header {
  max-width: 100%;
  border-bottom: 0; }

#picturebar { display:none; }
  
// things cut out of themes > shared > themes > project > css > global.css

.aside-widget img {
  display: block; }

.aside-widget h2,
.aside-widget h2:first-child {
  margin-top: 1.25rem;
  margin-bottom: .25rem;
  // font-weight: 400;
}

.aside-widget h2:before, .aside-widget h2:after {
    margin-top: 1.1rem; }

.aside-widget h3 {
  margin-top: 1.25rem;
  margin-bottom: .25rem;
  // font-weight: 400; 
}

.aside-widget a.button {
  margin-bottom: 0; }

.node-type-project .active-link,
.node-type-project-revision .active-link {
  background-color: transparent; }

.aside-widget .active-link a,
.view-ipums-current-project .active-link .aside-widget div[class^="link-node-"] {
  background-color: var(--link); }
  .aside-widget .active-link a a,
  .view-ipums-current-project .active-link .aside-widget div[class^="link-node-"] a {
    color: #fff;
    text-decoration: none; }

.btn-small, .aside-widget a.button {
  padding: 0.6em 1.39em 0.56em 1.39em; }
    


